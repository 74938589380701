import BaseModel from "../../../common/baseModel";

export default class ContractTemplateModel extends BaseModel {

  name = "电子合同模板";
  api = "/common/contractTemplate";

  fields() {
    return [
      {
        key: 'signerRoles',
        inputControl: 'select',
        inputControlProps: {
          multiple: true,
          allowCreate: true
        }
      }
    ];
  }

}
