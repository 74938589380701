import WaybillModel from "../../shipment/model/WaybillModel";

export default class ArtificialWaybillModel extends WaybillModel {

    name = "驾运运单";
    api = "/truck/artificialWaybill";
    detailApi = "/truck/artificialWaybillDetail";

    awbFields = [
        {
            key: 'driver',
            labelField: 'fullInfo',
            onChange(driver, column, app) {
                const formEl = app.$parent.$parent.$parent.$parent.$parent;
                driver.truckNo && formEl.updateData("truckNo", driver.truckNo);
                driver.truckLength && formEl.updateData("truckLength", driver.truckLength);
                driver.containerType && formEl.updateData("containerType", driver.containerType);

                if (driver.carrier && driver.carrier.carrierCollection) {
                    const bankAccount = `${driver.carrier.bankUser} ${driver.carrier.bank} ${driver.carrier.bankCardNo}`;
                    formEl.updateData("bankAccount", bankAccount);
                } else {
                    const bankAccount = `${driver.bankUser} ${driver.bank} ${driver.bankCardNo}`;
                    formEl.updateData("bankAccount", bankAccount);
                }

                if (driver.carrier && driver.carrier.id) {
                    console.log(111)
                    formEl.updateData("actualDriver", "");
                    formEl.updateData("actualDriverMobile", "");
                    formEl.updateData("actualDriverIdCard", "");
                } else {
                    console.log(2222)
                    formEl.updateData("actualDriver", driver.name);
                    formEl.updateData("actualDriverMobile", driver.mobile);
                    formEl.updateData("actualDriverIdCard", driver.idCard);
                    formEl.updateData("actualDriver", driver.name);
                }

                app.$forceUpdate();
            }
        },
        {
            key: "actualDriver",
            placeholder: "如为外包，此处必须写",
            type: "FOREIGN",
            preload: true,
            inputControl: "combo",
            foreignApi: "/carrier/artificialDriver",
            enableRemoteQuery: true,
            remoteQuery(keyword, callback, app) {
                if (!app || !app.formData || !app.formData.driver ) {
                    console.log('no driver')
                    callback({items: []});
                    return;
                }
                const {driver} = app.formData;
                const empty = {
                    items: []
                };

                if (!driver || !driver.carrier || !driver.carrier.id) {
                    callback(empty);
                    return;
                }

                if (!keyword) {
                    callback(empty);
                    return;
                }
                app.axios.get("/carrier/artificialDriver", {
                    params: {
                        page: 1,
                        limit: 30,
                        carrierId: driver.carrier.id,
                        'name|mobile': `%${keyword}%`
                    }
                }).then((res) => {
                    callback({
                        items: res.items.map(item => item.name)
                    });
                }).catch(() => {
                    callback(empty);
                })
            },
            onChange(val, column, app) {
                const {formData} = app;

                const formEl = app.$parent.$parent.$parent.$parent.$parent;
                if (!val) {
                    formEl.updateData("actualDriverMobile", "");
                    formEl.updateData("actualDriverIdCard", "");
                    return;
                }

                if (!formData.driver.carrier) {
                    return;
                }

                app.axios.get("/carrier/deliveryTruck", {
                    params: {
                        page: 1,
                        limit: 999,
                        carrierId: formData.driver.carrier.id
                    }
                }).then((res) => {
                    const map = {};
                    res.items.forEach(item => {
                        map[item.name] = item;
                    })

                    if (!map[val])  {
                        return;
                    }

                    formEl.updateData("actualDriver", map[val].name);
                    formEl.updateData("actualDriverMobile", map[val].mobile);
                    formEl.updateData("actualDriverIdCard", map[val].idCard);
                    if (map[val].truckNo) {
                        formEl.updateData("truckNo", map[val].truckNo);
                    }
                })
            },
            filterable: {
                type: "string",
                fuzzy: true,
                key: "driverName|driverMobile|carrierName"
            },
        }
    ];

    columnWidth = 6;

    formGrouping = [
        {label: "路线信息", columns: ["consignor", "consignorBase", "startArea", "customer", "unloadAreas"]},
        {label: "承运信息", columns: ["driver", "tempTruckNo", "loadUnloadType"]},
        {label: "实际承运", columns: [
                "actualDriver", "actualDriverMobile", "actualDriverIdCard",
                "bankAccount"
            ]},
        {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay", "wanjinOilPay", "deposit", "insuranceFee", "otherFee", "additionalPay"]}
    ];

    fields() {
        return super.fields().concat(this.awbFields);
    }

}
