<template>
  <div>
<!--    <div v-if="column.key === 'consignorBase'" style="position: fixed;left: 10px;top:20px; background: #fff;padding: 10px;z-index:9999;">-->
<!--      {{inputValue}}-->
<!--    </div>-->
    <el-select
      :size="size"
      filterable
      :remote="column.enableRemoteQuery"
      :default-first-option="true"
      reserve-keyword
      :remote-method="selectRemoteOptions"
      :readonly="!!column.readonly"
      :clearable="true"
      :multiple="!!column.inputControlProps.multiple"
      v-if="column.inputControl === 'combo'"
      @change="inputControlChanged"
      value-key="id"
      v-model="inputValue"
      v-bind="{...$attrs, ...column.inputControlProps}"
      :placeholder="placeholder || column.placeholder || '请选择'"
    >
      <el-option
        v-for="(item, index) in (column.options || [])"
        :key="index"
        :label="getDisplay(column, item)"
        :value="column.valueField ? item[column.valueField] : item">
      </el-option>
    </el-select>
    
    
    <el-select
      :size="size"
      :clearable="true"
      :automatic-dropdown="true"
      :multiple="!!column.inputControlProps.multiple"
      v-else-if="column.inputControl === 'select'"
      :readonly="!!column.readonly"
      collapse-tags
      filterable
      default-first-option
      :allow-create="false !== column.inputControlProps.allowCreate"
      value-key="id"
      @input="inputControlChanged"
      v-model="inputValue" :placeholder="placeholder || column.placeholder || '请选择'">
      <el-option
        v-for="(item, index) in (column.options || column.staticStore || [])"
        :key="index"
        :label="getDisplay(column, item)"
        :value="column.valueField ? item[column.valueField] : item">
      </el-option>
    </el-select>
    
    <el-cascader
      :size="size"
      v-else-if="column.inputControl === 'region'"
      :filterable="true"
      :clearable="true"
      @input="inputControlChanged"
      :readonly="!!column.readonly"
      :props="Object.assign({value: 'label'}, column.inputControlProps || {})"
      collapse-tags
      v-model="inputValue"  :placeholder="placeholder || column.placeholder || '请选择'"
      :options="cityData"></el-cascader>
    
    <el-switch
      :size="size"
      :readonly="!!column.readonly"
      @input="inputControlChanged"
      :placeholder="placeholder || column.placeholder || ''"
      v-else-if="column.inputControl === 'switch'"
      v-model="inputValue">
    </el-switch>
    
    <span v-else-if="column.inputControl === 'display'">
            {{typeof column.toString === 'function'
      ? column.toString(formData[column.key])
      : formData[column.key]}}
        </span>
    
    <el-date-picker
      :size="size"
      v-else-if="column.inputControl === 'date'"
      v-model="inputValue"
      @input="inputControlChanged"
      :type="column.dateType || 'date'"
      :format="column.dateFormat || 'yyyy-MM-dd'"
      :value-format="column.dateFormat || 'yyyy-MM-dd'"
      :placeholder="placeholder || column.placeholder || '选择日期'">
    </el-date-picker>
    
    <span v-else-if="column.inputControl === 'idUpload'">
            <id-upload :type="column.inputControlProps.type"
                       :column="column"
                       @uploaded="handleUploaded"
                       @input="inputControlChanged"
                       :accept-mime="column.inputControlProps.accept || 'image/png,image/jpg,image/jpeg'"
                       v-model="inputValue"></id-upload>
        </span>
    
    <span v-else-if="column.inputControl === 'rich'">
            <quill-editor v-model="inputValue"
                          :options="column.inputControlProps"
                          @change="inputControlChanged"></quill-editor>
        </span>
    
    <el-input v-else-if="column.inputControl === 'textarea'"
              :size="size"
              @focus="handleInputFocus"
              @input="inputControlChanged"
              :readonly="!!column.readonly"
              :rows="column.inputControlProps.rows || 20"
              :placeholder="placeholder || column.placeholder || ''"
              type="textarea"
              v-model="inputValue"></el-input>
    
    <el-input v-else
              :size="size"
              @focus="handleInputFocus"
              @input="inputControlChanged"
              :readonly="!!column.readonly"
              :placeholder="placeholder || column.placeholder || ''"
              :type="column.inputControlProps ? column.inputControlProps.type : 'text'"
              v-model="inputValue"></el-input>
  
  </div>
</template>

<script>
import __, {isArray} from "lodash";
import {cityData} from "../CityData";
import DataStructure from "../dataStructure";
import IdUpload from "./IdUpload";
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "InputControl",
  components: {IdUpload, quillEditor},
  props: {
    value: {},
    formData: {},
    columnOptions: {
      required: true
    },
    placeholder: {
      default: ""
    },
    formItemChanged: {
      type: Function,
      default: () => {}
    },
    size: {
      type: String,
      default: "small"
    }
  },
  data() {
    return {
      cityData: cityData,
      column: {},
      inputValue: null,
      rawInputValue: null
    };
  },
  methods: {
    selectRemoteOptions: __.debounce(function(keyword) {
      const column = this.column;
      column.remoteQuery(keyword, (res) => {
        if(res.items.length === 1 && column.autoSelect !== false) {
          // @todo 自动选择
        }
        this.$nextTick(() => {
          this.$set(
            column, "options",
            res.items.concat(
              this.inputValue instanceof Array ? this.inputValue : []
            )
          );
          this.$forceUpdate()
        })
      }, this)
    }, 10),
    inputControlChanged() {
      switch(this.column.inputControl) {
        case "select":
          if(!this.column.options && false !== this.column.inputControlProps.allowCreate && this.inputValue.length) {
            let options = this.column.options || this.column.staticStore || [],
              values = this._.isArray(this.inputValue) ? this.inputValue : [this.inputValue];
            values.forEach(item => {
              if(options.indexOf(item) < 0) {
                options.push(item);
              }
            });
            
            this.$set(this.column, "options", options);
          }
          break;
      }
      
      if (typeof this.column.onChange === 'function') {
        this.column.onChange(this.inputValue, this.column, this);
      }
      
      this.$set(this, 'inputValue', this.inputValue)
      this.$emit("input", this.inputValue, this.column.key, this.rawInputValue);
      this.$forceUpdate();
    },
    handleUploaded(res) {
      this.rawInputValue = res;
    },
    
    updateData(value) {
      if(typeof value === "function") {
        value = value();
      }
      
      if((this.column.inputControl === 'combo' || this.column.inputControl === 'select') && (!this.column.options || !this.column.options.length) && value) {
        if (isArray(value)) {
          this.$set(this.column, 'options', value)
        } else {
          this.$set(this.column, 'options', [value])
        }
        this.$forceUpdate();
      } else {
        this.inputValue = value;
      }
    },
    handleInputFocus(e) {
      e.target.select();
    },
    getDisplay: DataStructure.getInstance().getDisplay
  },
  
  mounted() {
    this.column = this.columnOptions;
    this.column.inputControlProps = this.column.inputControlProps || {};
    this.column.options = this.column.options || this.column.staticStore || [];
    
    const me = this;
    const dsInstance = DataStructure.getInstance();
    if(this.column.preload && this.column.foreignApi) {
      this.column.options = dsInstance.getPreLoadedForeign(this.column.foreignApi);
      this.column.inputControlProps = this.column.inputControlProps || {};
      this.column.inputControlProps.allowCreate = this.column.inputControlProps.allowCreate === undefined
        ? false
        : this.column.inputControlProps.allowCreate;
      if(!this.column.options && !dsInstance.isAlreadyLoadedApi(this.column.foreignApi)) {
        dsInstance.setAlreadyLoadedApi(this.column.foreignApi);
        this.column.remoteQuery(null, function(res) {
          dsInstance.setPreLoadedForeign(me.column.foreignApi, res.items);
          me.$set(me.column, "options", res.items);
          me.$forceUpdate()
        }, this)
      } else {
        this.$set(this.column, "options", me.column.options || dsInstance.getPreLoadedForeign(me.column.foreignApi) || []);
        this.$forceUpdate()
      }
    }
    
    // 默认值
    if(this.value !== undefined) {
      switch(this.column.inputControl) {
        case "combo":
          if((!this.column.options || !this.column.options.length) && this.value) {
            this.column.options = [this.value];
          }
          this.inputValue = this.value;
          break;
        case "select":
          // eslint-disable-next-line no-case-declarations
          let selectedValue = this.value
          if(this.column.inputControlProps.multiple) {
            if((!this.column.options || !this.column.options.length) && selectedValue) {
              this.column.options = selectedValue;
            }
          } else if(!this._.isObject(this.value)) {
            for(let i = 0; i<this.column.options.length; i++) {
              if(selectedValue === this.column.options[i].id) {
                selectedValue = this.column.options[i];
                break;
              }
            }
          }
          
          this.inputValue = selectedValue;
          break;
        case "region":
          if(this.column.inputControlProps.multiple) {
            if(!this._.isArray(this.value)) {
              this.inputValue = Array.from(this.value.split(","), item => {
                return item.split(" ");
              });
            } else {
              this.inputValue = this.value;
            }
          } else {
            if(!this._.isArray(this.value)) {
              this.inputValue = this.value.split(" ");
            } else {
              this.inputValue = this.value;
            }
          }
          break;
        default:
          this.inputValue = this.value;
      }
    }
  }
}
</script>

<style scoped>

</style>
