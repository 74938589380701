import BaseModel from "../../../common/baseModel";

export default class WaybillModel extends BaseModel {

  name = "运单";
  api = "/shipment/waybill";
  detailApi = "/shipment/waybillDetail";

  quickEdit = false;

  formGrouping = [
    {label: "路线信息", columns: ["consignor", "consignorBase", "startArea", "customer", "unloadAreas"]},
    {label: "承运信息", columns: ["driver", "truckNo", "loadUnloadType", "truckLength", "containerType", "tempTruckNo", "additionalPay"]},
    {label: "付款信息", columns: ["totalAmount", "prePay", "arrivePay", "receivePay", "oilCardPay", "factoryOilPay", "wanjinOilPay",  "deposit", "insuranceFee", "otherFee", "returnBillNo"]}
  ];

  fields() {
    return [
      {
        key: "details",
        visibleInForm: false,
        visibleInGrid: false,
        visibleInDetail: false
      },
      {
        key: "startArea",
        inputControl: "region"
      },
      {
        key: "unloadAreas",
        inputControl: "region",
        inputControlProps: {
          multiple: true
        }
      },
      {
        key: "loadUnloadType",
        inputControl: "select"
      },
      {
        key: "returnBillNo",
        placeholder: "多个用英文逗号分割",
        inputControl: "select",
        inputControlProps: {
          multiple: true
        }
      },
      {
        key: "unloadAreasCount",
        visibleInForm: false
      },
      {
        key: "contractList",
        visibleInForm: false
      },
      {
        key: "latestContract",
        visibleInForm: false
      },
      {
        key: "paymentBillNo",
        visibleInForm: false
      },
      {
        key: "symbol",
        filterable: {
          key: "symbol|outBillNo"
        }
      },
      {
        key: "status",
      },
      {
        key: "consignor",
        onChange: (value, column, inputControl) => {
          const form = inputControl.$parent.$parent.$parent.$parent.$parent;

          if (!value) {
            form.updateControl({
              key: "consignorBase"
            }, [])
            form.$forceUpdate()
            return;
          }

          form.updateControl({
            key: "consignorBase"
          }, value.consignorBase?.map(item => item.name))

          form.$forceUpdate()
        }
      },
      {
        key: "consignorBase",
        inputControl: "select",
        onChange: (value, column, inputControl) => {
          if (!value) {
            return;
          }

          const form = inputControl.$parent.$parent.$parent.$parent.$parent;

          const item = form.formData.consignor?.consignorBase?.find(item => item.name === value);
          form.updateData('startArea', item?.area);
          form.$forceUpdate()
        }
      },
      {
        key: "driver",
        remoteQueryField: "name|mobile|symbol",
        getDisplay(row) {
          return row?.fullInfo;
        },
        filterable: {
          type: "string",
          fuzzy: true,
          key: "driverName|driverMobile|carrierName"
        },
        placeholder: "输入姓名或手机号搜索"
      },
      {
        key: "unloadAreas",
      },
      {
        key: "arrivePay",
        readonly: true
      },
      {
        key: 'bankAccount',
        label: "运费支付至",
        columnWidth: 24,
        readonly: true,
        placeholder: "此运费依据选择的承运方显示，发运时请再次确认"
      },

    ];
  }

}
