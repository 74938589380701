import routerUtils from "@/router/routerUtils";

import ConfigureModel from "./model/ConfigureModel";
import ExportTaskModel from "./model/ExportTaskModel";
import ApproveProcessInstanceModel from "./model/ApproveProcessInstanceModel";
import OperationLogModel from "./model/OperationLogModel";
import ItemTagModel from "./model/ItemTagModel";
import DashboardWidgetModel from "./model/DashboardWidgetModel";
import ContractTemplateModel from "./model/ContractTemplateModel";
// IMPORT_HOLDER

let routes = Array.from([
    ConfigureModel,
    ExportTaskModel,
    ApproveProcessInstanceModel,
    OperationLogModel,
    ItemTagModel,
    DashboardWidgetModel,
    ContractTemplateModel,
    // MODEL_HOLDER
], model => routerUtils.templateRouter(model));

routes = routes.concat([
    {
        path: '/', // 默认页和父级路由一致
        name: 'Home',
        component: routerUtils.importPage('common/home/Home'),
        meta: {
            title: '工作台',
            icon: "el-icon-s-home",
            closable: false
        }
    },
    {
        path: '/navigation',
        name: 'navigation',
        component: routerUtils.importPage('common/view/navigation/navigationPage'),
        meta: {
            title: '导航',
            icon: "el-icon-discover",
        }
    },
    {
        path: '/common/userMenu', // 默认页和父级路由一致
        component: routerUtils.importPage('common/userMenu'),
        meta: {
            title: '菜单管理' // 页签标题
        }
    },
]);

export default routes;
